/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_appsync_graphqlEndpoint": "https://wvni7lr2dfbk3achbce34qdcee.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_cognito_identity_pool_id": "ca-central-1:c1db8177-a386-4e80-a41f-2ef336b514e4",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_8fpP8q7ug",
    "aws_user_pools_web_client_id": "7lkbquivtir0coo5ajtmkpdeq0",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "dodoappbucketname230138-master",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
